import { BaseComponent } from "@abstract/BaseComponent";
import { Component } from "@angular/core";
import { Const } from "@const/Const";
import { PaginationData } from '@app/model/PaginationData';
import { PreQuickOrders, PreQuickOrderSummary } from "@wearewarp/types/rest-api/admin/form-data/shipment-entry";

interface TabData {
  key: string, name: string, apiPath: string, data: PaginationData<PreQuickOrders>, actions?: any[]
}

@Component({
  selector: "[quick-orders]",
  templateUrl: './index.html',
  styleUrls: [],
})
export class QuickOrders extends BaseComponent {

  readonly tabs: TabData[] = [
    {
      key: 'needReview',
      name: 'Need Review',
      apiPath: 'need_review',
      data: new PaginationData(),
    },
    {
      key: 'all',
      name: 'All',
      apiPath: 'all',
      data: new PaginationData(),
    },
  ];
  
  tabIndex = -1;
  get currentTab(): TabData {
    return this.tabs[this.tabIndex];
  }
  private sumData: PreQuickOrderSummary;

  constructor() {
    super();
    this.autoShrinkSideBar();
    this.getSummary();
  }

  private getSummary() {
    let url = Const.APIURI_QUICK_ORDERS('summary');
    this.api.GET(url).subscribe(
      resp => {
        this.sumData = resp.data;
      }, err => {
        this.showErr(err);
      }
    );
  }

  getTabLabel(tab: TabData): string {
    let key = tab.key;
    let count = this.sumData?.[key] ?? 0;
    return `${tab.name} (${count})`;
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    const {tab} = this.queryParams;
    if (!tab || !this.isTabValid(tab)) {
      return this.routeWithQueryUrl({tab: this.tabs[0].key});
    }
    let index = this.tabKeyToIndex(tab);
    if (this.tabIndex != index) {
      this.tabIndex = index;
    }
  }

  private isTabValid(key: string): boolean {
    return this.tabs.filter(it => it.key == key).length == 1;
  }

  private tabKeyToIndex(key: string): number {
    for (let i = 0; i < this.tabs.length; i++) {
      if (this.tabs[i].key == key) {
        return i;
      }
    }
    return 0;
  }

  private tabIndexToKey(index: number): string {
    if (index >= 0 && index < this.tabs.length) {
      return this.tabs[index].key;
    }
    return this.tabs[0].key;
  }

  onTabChange(event) {
    this.routeWithQueryUrl({tab: this.tabIndexToKey(event.index), page: 1}, true);
  }

  onSumDataUpdated(tab: TabData, data: {total: number}) {
    if (!this.sumData) {
      return;
    }
    this.sumData[tab.key] = data.total;
  }
  

}