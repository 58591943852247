import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseList } from "@app/admin/base/list";
import { NavigationEndData } from "@app/interfaces";
import { Const } from "@const/Const";
import { ModalHelper } from "@wearewarp/ng-antd";
import { DialogService } from "@dialogs/dialog.service";
import { NzDrawerService } from "ng-zorro-antd/drawer";
import { PreQuickOrders } from "./interface";
import { DateUtil } from "@services/date-utils";
import { ReviewQuickOrders } from "../review-quick-orders";

@Component({
  selector: '[list-quick-orders]',
  templateUrl: './view.html',
  styleUrls: ['./style.scss']
})
export class ListQuickOrders extends BaseList<PreQuickOrders> {
  @Input() apiPath: string;
  @Input() isVisible: boolean = false;
  @Output() sumDataUpdated = new EventEmitter<any>();

  get isTabNeedReview(): boolean { return this.apiPath == 'need_review' }
  get isTabAll(): boolean { return this.apiPath == 'all' }

  get hasAction(): boolean { return this.isTabNeedReview }
  get hasStatus(): boolean { return this.isTabAll }

  constructor(private modalHelper: ModalHelper, private nzDrawerService: NzDrawerService) {
    super();
    this.subscribeNavigationEnd();
  }

  // Gọi trong hàm constructor
  protected subscribeNavigationEnd() {
    this.subscription.add(this.appComponent.subscribeNavigationEnd((e: NavigationEndData) => {
      if (this.isVisible) {
        this.onNavigationEnd(e.current.url);
      }
    }));
    if (this.appComponent.getCurrentNavigationUrlData()) {
      // Đợi kết thúc hàm constructor rồi mới thực hiện
      setTimeout(() => this.onNavigationEnd(this.appComponent.createNavigationEndData().current.url), 0);
    }
  }

  protected handleNavigationEnd(url: string, prevQueryParam: any): void {
    super.handleNavigationEnd(url, prevQueryParam);
  }

  protected getApiUrl(): string {
    return Const.APIURI_QUICK_ORDERS(this.apiPath);
  }

  protected getDataDone(resp: any): void {
    this.processData(resp);
    super.getDataDone(resp);
  }

  private processData(resp) {
    let total: number = resp.data.total ?? 0;
    this.sumDataUpdated.emit({total});
    if (!resp.data.list_data) {
      return;
    }
    for (let item of resp.data.list_data) {
      this.processItem(item);
    }
  }

  private processItem(item: PreQuickOrders) {
    let pickLocation;
    let pickLocationId = item.requestData?.pickLocationId;
    if (pickLocationId) {
      pickLocation = item.locations?.find(item => item.id == pickLocationId);
    }
    item.pickLocationName = pickLocation?.name || 'N/A';
    item.pickAddress = this.getAddressText(pickLocation?.pickAddr) || '';
    item.createRequestTime = DateUtil.displayLocalTime(item.insert?.when,{ timezone: DateUtil.Timezone_LA});
    let shortTimezoneCreateRequest = DateUtil.timezoneStandardToUsShort(DateUtil.Timezone_LA);
    item.createRequestTime = `${item.createRequestTime} ${shortTimezoneCreateRequest}`;
    let pickTimezone = pickLocation?.pickAddr?.metadata?.timeZoneStandard || DateUtil.Timezone_LA;
    item.pickDate = DateUtil.displayLocalTime(item.requestData?.requestPickupDate, {
      timezone: pickTimezone, format: 'MM/DD/YYYY'
    });
    if (this.apiPath == 'need_review') {
      item.actions = [
        {name: 'Review', handler: this.openOrderDetail.bind(this), isPermission: this.requirePermissions([this.PermissionCode.quick_order.review])}
      ]
    }
  }

  private openOrderDetail(item) {
    DialogService.openDialog(ReviewQuickOrders, {
      nzMaskClosable: false,
      nzComponentParams: {
        model: item,
        onOk: () => { this.onBtnRefresh() }
      },
      nzClassName: "modal-xxl",
    });
  }


}