import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuickOrders } from '.';


const quickOrderRoutes: Routes = [
  {
    path: '', component: QuickOrders, data: { title: 'Quick Order - WARP Admin' }, children: [
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(quickOrderRoutes)],
  exports: [RouterModule]
})
export class QuickOrderRoutingModule { }
