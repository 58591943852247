import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { SharedModule } from '../shared/shared.module'
import { QuickOrders } from '.';
import { ListQuickOrders } from './list-quick-orders';
import { ReviewQuickOrders } from './review-quick-orders';
import { QuickOrderRoutingModule } from './quickOrder.route';
import { DetailModule } from '../base/detail.module';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    QuickOrderRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NzTableModule,
    NzIconModule,
    NzButtonModule,
    DetailModule,
    SharedModule,
  ],
  declarations: [
    QuickOrders,
    ListQuickOrders,
    ReviewQuickOrders
  ],
  exports: [
    QuickOrders
  ]
})
export class QuickOrdersModule { }
