<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>Review Quick Order</div>
  </div>
</ng-template>
<div style="display: flex; background-color: #F0F2F5;">
  <div style="width: 330px; padding-right: 20px; background: #FFFFFF;">
    <div class="title bottom20 bold">Form Request</div>
    <div class="row-request">
      <div class="label-request">Customer: </div>
      <div class="content-request">{{ model?.client?.name || 'N/A' }}</div>
    </div>
    <div class="row-request">
      <div class="label-request">Email: </div>
      <div class="content-request"> {{ model?.requestData?.requestByEmail || 'N/A' }}</div>
    </div>
    <div class="row-request">
      <div class="label-request">Filled by: </div>
      <div class="content-request">{{ model?.requestData?.filledBy || 'N/A' }}</div>
    </div>
    <div class="row-request">
      <div class="label-request">Pickup Location Name:</div>
      <div class="content-request">{{ model?.pickLocationName || 'N/A' }}</div>
    </div>
    <div class="row-request">
      <div class="label-request">Pickup Location Address:</div>
      <div class="content-request">{{ model?.pickAddress || 'N/A' }}</div>
    </div>
    <div class="row-request flex">
      <div class="label-request">Number Of Pallets: </div>
      <div class="content-request left5">{{ model?.requestData?.numberOfPallets || 'N/A' }}</div>
    </div>
    <div class="row-request">
      <div class="label-request">Requested Pickup Date: </div>
      <div class="content-request">{{ model?.pickDate || 'N/A' }}</div>
    </div>
    <div class="row-request">
      <div class="label-request">Requested Pickup Window: </div>
      <div class="content-request">{{ model?.requestData?.requestPickupWindow || 'N/A' }}</div>
    </div>
  </div>
  <div style="background: #FFFFFF; width: 100%;">
    <div class="title bottom20 bold">Order Information</div>
    <div style="display: flex; flex-direction: row; margin-bottom: 20px;">
      <div class="flex" style="align-items: center;">
        <img alt="Customer" class="icon" src="assets/img/ic_shipment_info_person.svg" />
      <span>{{ model?.client?.name || 'N/A' }}</span>
      </div>
      <div style="display: flex; margin-left: 100px; align-items: center;">
        <img alt="Truck type" class="icon" src="assets/img/ic_shipment_info_truck.svg" />
        <span>{{ displayInfo?.shipmentType }}</span>
      </div>
    </div>
    <div>
      <div class="shipment-info">
        <div class="pick-info">
          <nz-collapse class="collapse-form-location">
            <nz-collapse-panel [nzHeader]="tplPick" [nzShowArrow]="false">
              <ng-template #tplPick>
                <div class="mainTitleCollapse">
                  <img src="assets/img/location-icon.svg">
                  <span class="pickup-tag">Pickup</span> 
                  <span class="left5"> {{displayInfo?.pickInfo?.locationName}} </span>
                  <span class="font-regular left10"> {{displayInfo?.pickInfo?.address}}</span>
                </div>
                <div nz-row class="subTitleCollapse">
                  <div nz-col nzXXl="12" nzXl="12" nzLg="12" class="appointmentDate flex" style="flex-wrap: wrap;">
                    <div class="flex right20">
                      <img src="assets/img/appointment-date.svg" style="align-self: flex-start; margin-right: 5px;">
                      <div [innerHTML]="displayInfo?.pickInfo?.windowTime"></div>
                    </div>
                    <div *ngIf="displayInfo?.pickInfo?.isAppointmentRequired">
                      <img src="assets/img/appointment-date.svg" style="margin-right: 5px;">
                      <span class="font-medium">Requires Appointment</span>
                    </div>
                  </div>
                </div>
              </ng-template>
              <div nz-row class="info">
                <div nz-col nzSpan="10">
                  <div class="label-info primaryContact">Primary Contact: {{ displayInfo?.pickInfo?.primaryContact }}</div>
                  <div class="label-info">Phone Number: {{ displayInfo?.pickInfo?.primaryPhoneNumber }}</div>
                  <div class="label-info">Email: {{ displayInfo?.pickInfo?.primaryEmail }}</div>
                  <div class="label-info referenceNo">Reference No: {{ displayInfo?.pickInfo?.refNums }}</div>
                  <div class="label-info instructions">Instructions: {{ displayInfo?.pickInfo?.instructions }}</div>
                </div>
                <nz-divider class="divider-info" nzType="vertical"></nz-divider>
                <div nz-col nzSpan="10" nzOffset="1">
                  <div class="label-info secondaryContact">Secondary Contact: {{ displayInfo?.pickInfo?.secondaryContact }}</div>
                  <div class="label-info">Phone Number: {{ displayInfo?.pickInfo?.secondaryPhoneNumber }}</div>
                  <div class="label-info">Email: {{ displayInfo?.pickInfo?.secondaryEmail }}</div>
                  <div class="label-info accessCode">Access Code: {{ displayInfo?.pickInfo?.accessCode }}</div>
                  <div class="label-info notes">Notes: {{ displayInfo?.pickInfo?.notes }}</div>
                </div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
        <div class="pick-info top20">
          <nz-collapse class="collapse-form-location">
            <nz-collapse-panel [nzHeader]="tplDrop" [nzShowArrow]="false">
              <ng-template #tplDrop>
                <div class="mainTitleCollapse">
                  <img src="assets/img/location-icon.svg">
                  <span class="dropoff-tag">Delivery</span> 
                  <span class="left5"> {{displayInfo?.dropInfo?.locationName}}</span>
                  <span class="font-regular left10"> {{displayInfo?.dropInfo?.address}}</span>
                </div>
                <div nz-row class="subTitleCollapse">
                  <div nz-col nzXXl="12" nzXl="12" nzLg="12" class="appointmentDate flex" style="flex-wrap: wrap;">
                    <div class="flex right20">
                      <img src="assets/img/appointment-date.svg" style="align-self: flex-start; margin-right: 5px;">
                      <div [innerHTML]="displayInfo?.dropInfo?.windowTime"></div>
                    </div>
                    <div *ngIf="displayInfo?.dropInfo?.isAppointmentRequired">
                      <img src="assets/img/appointment-date.svg" style="margin-right: 5px;">
                      <span class="font-medium">Requires Appointment</span>
                    </div>
                  </div>
                </div>
              </ng-template>
              <div nz-row class="info">
                <div nz-col nzSpan="10">
                  <div class="label-info primaryContact">Primary Contact: {{ displayInfo?.dropInfo?.primaryContact }}</div>
                  <div class="label-info">Phone Number: {{ displayInfo?.dropInfo?.primaryPhoneNumber }}</div>
                  <div class="label-info">Email: {{ displayInfo?.dropInfo?.primaryEmail }}</div>
                  <div class="label-info referenceNo">Reference No: {{ displayInfo?.dropInfo?.refNums }}</div>
                  <div class="label-info instructions">Instructions: {{ displayInfo?.dropInfo?.instructions }}</div>
                </div>
                <nz-divider class="divider-info" nzType="vertical"></nz-divider>
                <div nz-col nzSpan="10" nzOffset="1">
                  <div class="label-info secondaryContact">Secondary Contact: {{ displayInfo?.dropInfo?.secondaryContact }}</div>
                  <div class="label-info">Phone Number: {{ displayInfo?.dropInfo?.secondaryPhoneNumber }}</div>
                  <div class="label-info">Email: {{ displayInfo?.dropInfo?.secondaryEmail }}</div>
                  <div class="label-info accessCode">Access Code: {{ displayInfo?.dropInfo?.accessCode }}</div>
                  <div class="label-info notes">Notes: {{ displayInfo?.dropInfo?.notes }}</div>
                </div>
              </div>
            </nz-collapse-panel>
          </nz-collapse>
        </div>
        <div class="top20">
          <div nz-row class="item" style="margin: 0px" *ngFor="let shipmentItem of displayInfo?.items">
            <div nz-col nzSpan="20">
              <div class="item-specifications-info">
                <div class="flex">
                  <div class="item-name item-label right10">{{shipmentItem.name}}</div>
                  <div *ngIf="shipmentItem.sum?.desc">{{shipmentItem.sum?.desc}},</div>
                  <div *ngIf="!shipmentItem.sum?.desc">
                    <span *ngIf="shipmentItem.qty">{{shipmentItem.qty}} {{shipmentItem.qtyUnit}}</span>
                    <span *ngIf="shipmentItem.weight">, {{shipmentItem.weight}} {{shipmentItem.weightUnit}}</span>
                    <span *ngIf="shipmentItem.length || shipmentItem.shipmentItem || shipmentItem.height">
                      <span>, {{shipmentItem.length}}</span>
                      <span>x{{shipmentItem.width}}</span>
                      <span>x{{shipmentItem.height}} </span>
                      <span>{{shipmentItem.sizeUnit}}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="separator h top30"></div>
<div form-footer class="no-border"
  [onProgress]="onProgress"
  [canClickOK]="!onProgress" 
  [canClickCancel]="!onProgress"
  nzIconOK="check" nzIconCancel="close" labelOK="Approve & Create Order" labelCancel="Reject" nzOkDanger="false" 
  (onOK)="onBtnApprove()" (onCancel)="onBtnReject()">
</div>